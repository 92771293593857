import React from 'react';
import styles from "./HeaderMenu.module.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
function HeaderMenu(props) {

  return (
    <ul className={styles.headerMenu}>
      {
        props.menuItems.map((link)=>
          <li className={styles.headerMenuItem}><Link to={link.href}>{link.title}</Link></li>
        )
      }

    </ul>
  );
}
export default HeaderMenu;
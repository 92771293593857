import React from 'react';
import styles from './Banner.module.scss';
import character from '../../assets/images/char.png';
import androidButton from '../../assets/images/playstore_download.png';

function Banner(props) {
  return (
    <div className={`${styles.banner} ${props.compressed ? styles.bannerMini : ""}`}>
      <div className={styles.bannerWrapper}>

        <img src={character} alt="" className={styles.character} />
        <div className={styles.bannerContent}>
          <h1 className={styles.bannerHeading}>
            {props.children}
          </h1>
          <p className={styles.bannerText}>
            Become a great warrior, slaying hundreds of demons and other foes, or become a skilled miner, creating armour pieces from raw ores in Curse of Aros!
          </p>
          <div className={styles.bannerAction}>
            <div className={styles.bannerDownloadButtons}>
              <img src={androidButton} className={styles.playStoreButton} />
              <img src="https://curseofaros.com/img/app-store.svg" className={styles.playStoreButton} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
export default Banner;
import React from 'react';
import styles from './Bookmarks.module.scss';


function Bookmarks(props) {

  return (
    <div className={styles.bookmarksWrapper}>
        <ul className={styles.bookmarks}>
            {props.links.map((item)=>{
                return(
                    <li className={styles.bookmarkItem}>
                        <a href={item.link} className={styles.bookmarkItemLink}>{item.text}</a>
                     </li>
                )
            })}
                
        </ul>
    </div>
  );
}
export default Bookmarks;
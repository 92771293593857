import React from 'react';
import styles from './Post.module.scss'
import postImage from '../../assets/images/postImage.png';

function Post(props) {
  const {title, author, body} = props;
  return (
    <article className={styles.post}>
      <h3  className={styles.postTitle}>
          {title}
      </h3>
      <p className={styles.postMeta}>
        Posted by <span className={styles.postAuthor}>{author}</span>, 1/1/2020 12:03 PM
      </p>
      <div className={styles.postContent} dangerouslySetInnerHTML={{__html: body}}>
      </div>

    </article>
  );
}
export default Post;
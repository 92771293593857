import React, {useState} from 'react';
import Select from 'react-select';

import uk from "../../../assets/images/uk.png";

function CountrySelect(props) {

  const options = [
    { value: {lang: "En", image: uk}, label: 'En' },
    { value: {lang: "De", image: ""}, label: 'De' },
    { value: {lang: "Fr", image: ""}, label: 'Fr' },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption)
  };

  
  const customStyles = {
    control: (derived)=>{
      return {...derived ,backgroundColor: "#182c2a", borderRadius: 0, border: 0, color: "#bfbeb8" }
    },
    singleValue: (derived)=>{
      return {...derived, color: "#bfbeb8", fontSize: "18px" }
    },
    indicatorSeparator: (derived)=>{
      return {...derived, display: "none" }
    }
  }

  return (
    <>
      <Select
          value={selectedOption}
          onChange={handleChange}
          isSearchable = {false}
          options={options}
          styles={customStyles}

      />
    </>

  );
}
export default CountrySelect;
import React from 'react';
import styles from './Footer.module.scss'
function Footer(props) {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <section className={styles.footerLogo}>
        <h3 className={styles.footerLogoHeading}>
          <span className={styles.footerLogoLabel}>
            A Game by
          </span>
          <br />
          <span>
            BitGate
          </span>
        </h3>
        </section>
        <section className={styles.footerNav}>
          <ul className={styles.footerNavList}>
            <li><a href="">Nullam</a></li>
            <li><a href="">Liberometusl</a></li>
            <li><a href="">Pharetr</a></li>
            <li><a href="">Alat arcul</a></li>
          </ul>
          <ul className={styles.footerNavList}>
            <li><a href="">Nullam</a></li>
            <li><a href="">Liberometusl</a></li>
            <li><a href="">Pharetr</a></li>
            <li><a href="">Alat arcul</a></li>
          </ul>
          <ul className={styles.footerNavList}>
            <li><a href="">Nullam</a></li>
            <li><a href="">Liberometusl</a></li>
            <li><a href="">Pharetr</a></li>
            <li><a href="">Alat arcul</a></li>
          </ul>
        </section>

      </div>
      <section className={styles.copyright}>
        <div>
          &copy; 2020. All Rights Reserved
        </div>
      </section>
    </div>
  );
}
export default Footer;
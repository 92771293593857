import React, {useState} from 'react';
import styles from "./HeaderBar.module.scss";
import HeaderMenu from "./HeaderMenu";
import CountrySelect from './CountrySelect/CountrySelect'
import {Link} from "react-router-dom";

function HeaderBar(props) {
  const firstMenu = [
    {
      title: "Home",
      href: ""
    },
    {
      title: "Community",
      href: ""
    },
  ];
  const secondMenu = [
    {
      title: "Highscores",
      href: "/highscores"
    },
    {
      title: "Downloads",
      href: "/downloads"
    },
  ]

  return (
    <header className={styles.headerBar}>
      <div className={styles.headerContainer}>
      <HeaderMenu menuItems={firstMenu} />
      <h1 className={styles.logo}>Curse Of Aros</h1>
      <HeaderMenu menuItems={secondMenu} />
      </div>
      <section className={styles.localization}>
        <div className={styles.selectWrapper}>
        <CountrySelect />
        </div>

      </section>
      <section className={styles.authentication}>
        <Link to="/downloads" className={`${styles.authenticationButton} ${styles.green}`}>
          PLAY FOR FREE
        </Link>
      </section>
      <div className="line-left" role="presentation"></div>
      <div className="line-right" role="presentation"></div>

    </header>
  );
}
export default HeaderBar;
import React from 'react';
import styles from './Sidebar.module.scss';
import discord from '../../assets/images/Discord.png';
function Sidebar(props) {
  return (
    <div className={styles.sidebar}>
      <div className={styles.count}>
          <h4 className={styles.countNumber}>216</h4>
          <p className={styles.countLabel}>Players Online</p>
      </div>
      <div className={styles.worldMapSection}>
        <div className={styles.worldMap}>
          <a className={styles.worldMapLink} href="">World <br /> Map</a>
        </div>
        <p className={styles.worldMapLinkLabel}>
          * Click on the map to see the population.
        </p>
      </div>

      <div className={styles.discordWrapper}>
        <a href="https://discord.gg/mp7W8j8" target="_blank"><img src={discord} alt="" className={styles.discord}/></a>
      </div>

      <div className={styles.social}>
        <h3 className={styles.socialLabel}>
          Get Social
        </h3>
        <ul className={styles.socialLinks}>
          <li className={styles.socialItem}>
            <a className={`${styles.socialLink} ${styles.socialLinkFacebook}`} href="https://www.facebook.com/curseofaros">Facebook</a>
          </li>
          <li className={styles.socialItem}>
            <a className={`${styles.socialLink} ${styles.socialLinkTwitter}`} href="https://twitter.com/curseofaros">Twitter</a>
          </li>
          {false && <li className={styles.socialItem}>
            <a className={`${styles.socialLink} ${styles.socialLinkTwitch}`} href="">Twitch</a>
          </li>}
          {false && <li className={styles.socialItem}>
            <a className={`${styles.socialLink} ${styles.socialLinkYoutube}`} href="">Youtube</a>
          </li>}
        </ul>
      </div>

    </div>
  );
}
export default Sidebar;
export default () => ({
    title: {
      height: '0px',
    },
    header: {
      fontSize: '19px',
      fontWeight: '400',
      fontColor: '#425e50',
      fontColorActive: '#425e50',
      backgroundColor: 'transparent',
      borderWidth: '2px',
      borderColor: 'rgba(10, 15, 14, 0.6)',


    },

    rows: {
      fontSize: '17px',
      fontColor: '#c0beba',
      backgroundColor: 'transparent',
      stripedColor: '#0e1513',

    },

    pagination: {

      fontColor: '#c0beba',
      buttonFontColor: '#c0beba',

    },

  });
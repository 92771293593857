import React from 'react';
import styles from './Downloads.module.scss';
import SubPage from '../../components/SubPage/SubPage';
import windowsLogo from '../../assets/images/windows_logo.png';
import androidLogo from '../../assets/images/android.png';
import appleLogo from '../../assets/images/apple.png';
import DownloadCard from "./DownloadCard";
const bookmarks = [
  {
    link: "/",
    text: "Home"
  },
  {
    link: "/",
    text: "Subpage"
  },
  {
    link: "/",
    text: "Downloads"
  }
]
const downloadData = [
  {
    key: "win",
    logo: windowsLogo,
    platform: "Windows",
    description: "Note: The Windows version is only available to Patreon supporters.",
    downloadSize: "30",
    link: "#"
  },
  {
    key: "and",
    logo: androidLogo,
    platform: "Android",
    description: "Download Curse of Aros directly from the Google Play store.",
    downloadSize: "30",
    link: "#"
  },
  {
    key: "iphone",
    logo: appleLogo,
    platform: "iPhone",
    description: "Download Curse of Aros from the official App Store directly to your iPhone or iPad.",
    downloadSize: "30",
    link: "#"
  }
]
function Downloads(props) {
  return (
    <SubPage bookmarks={bookmarks}>
        <div className={styles.downloads}>
          <p>
          Play Curse of Aros entirely free today. Download the (tiny) game files and dive straight into our world!
          </p>
          <ul className={styles.downloadCards}>
            {downloadData.map((data)=><DownloadCard {...data} />)}
          </ul>
        </div>
    </SubPage>


    
  );
}
export default Downloads;
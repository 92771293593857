import React from 'react';
import './App.module.scss';
import { HeaderBar, Banner, News, Sidebar, Footer, Downloads, HighScore } from './sections';
import styles from './App.module.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className={styles.pageWrapper}>
        <div className="page">
          <HeaderBar />
          
          
          <Switch>
              <Route path="/downloads">
                <Banner compressed={true}>
                  Downloads
                </Banner>
              </Route>
              <Route path="/highscores">
                <Banner compressed={true}>
                  High Scores
                </Banner>
              </Route>
              <Route path="/">
                <Banner compressed={false}>
                  Start your journey.
                </Banner>
              </Route>
            </Switch>

          <main className={styles.mainContent}>
            <Switch>
              <Route path="/downloads">
                <Downloads />
                <Sidebar />
              </Route>
              <Route path="/highscores">
              <HighScore />
              </Route>
              <Route path="/">
                <News />
                <Sidebar />
              </Route>

            </Switch>
            
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;

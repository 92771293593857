import React from 'react';
import styles from './News.module.scss';
import Post from "./Post";

function News(props) {
  const sampleBody = `<p>
<img src="https://i.imgur.com/cz3F1gN.png"/>
</p>
<p>The wait is over. Varaxis is here, and it's big. It brings you various new monsters, which include the Golemite Bat and the Golemite Fiend, but also open up the gates to The Golem and Umbra, two tough new bosses that drop the pieces to 2 new equipment sets, requiring level 85 and 90. 
</p>
<p>The new sets are untradable, but the ingredients can be traded.
</p>`;

  return (
    <section className={styles.news}>
      <div className={styles.newsContainer}>
        <header className={styles.newsHeader}>
          <h3>Latest News</h3>
          <button className={styles.transparentButton}>Archived News</button>
        </header>

        <div className={styles.posts}>
          <Post title="Update #13: Varaxis" author="Bart" body={sampleBody} />
          <Post title="Update #14: Varaxis" author="Bart" body={sampleBody} />
          <Post title="Update #15: Varaxis" author="Bart" body={sampleBody} />
          <Post title="Update #16: Varaxis" author="Bart" body={sampleBody} />

          <div className={styles.bottomActions}>
            <button className={styles.transparentButton}>Archived News</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
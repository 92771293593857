import React, {useState, useEffect} from 'react';

import styles from './HighScore.module.scss';
import SubPage from '../../components/SubPage/SubPage';
import DataTable from 'react-data-table-component';
import theme from './datatableTheme';
import Card from '../../components/Card/Card';
import axios from 'axios';

import Select from 'react-select';


console.log(theme);
const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Level',
      selector: 'level',
      sortable: true,
      style: {
        color: '#67b490',
        fontFamily: 'Cambria, serif'
      },
    },
    {
      name: 'XP',
      selector: 'xp',
      sortable: true,
      style: {
        color: '#d2b054',
        fontFamily: 'Cambria, serif'
      },
    },
  ];







const bookmarks = [
    {
      link: "/",
      text: "Home"
    },
    {
      link: "/",
      text: "Subpage"
    },
    {
      link: "/",
      text: "High Scores"
    }
];


const options = [
  { value: "All", label: 'All' },
  { value: "Mining", label: 'Mining' },
  { value: "Smithing", label: 'Smithing' },
];

const highscoresEndpoint = '//curseofaros.com/highscores.json';
function HighScore(props) {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [nameFilter, setNameFilter] = useState("");
  const [data, setData] = useState([]);
  const handleChange = selectedOption => {
    setSelectedOption(selectedOption)
  };
  const handleChangeOfName = event => {
    setNameFilter(event.target.value)
  };

  useEffect(()=>{
    axios.get(highscoresEndpoint).then(resp => {
      const data = resp.data.map(e => ({
          name: e.name,
          level: '12',
          category: 'Flying',
          xp: 2000
      }));
      setData(data);
    });
  },[]);


  const filteredData = data.filter((val)=>{
    const categoryMatch =  selectedOption.value === "All" ? true : val.category === selectedOption.value;
    const nameMatch = nameFilter === "" || nameFilter == null ? true: val.name.toLowerCase().includes(nameFilter.toLowerCase());
    return categoryMatch && nameMatch;
  });

  const customStyles = {
    control: (derived)=>{
      return {...derived ,backgroundColor: "#0f1613", borderRadius: 0, border: 0, color: "#bfbeb8" }
    },
    singleValue: (derived)=>{
      return {...derived, color: "#bfbeb8", fontSize: "18px" }
    },
    indicatorSeparator: (derived)=>{
      return {...derived, display: "none" }
    }
  }

  return (
    <SubPage bookmarks={bookmarks}>
        <div className={styles.highscores}>
          <div className={styles.filtersWrapper}>
            <Card>
              <div className={styles.filters}>
                <div className={styles.filter}>
                  <label className={styles.filterLabel} htmlFor="">Select Category</label>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    isSearchable = {false}
                    options={options}
                    styles={customStyles}
                  />
                </div>

                <div className={styles.filter}>
                  <label className={styles.filterLabel} htmlFor="">Player Name</label>
                  <input
                    type="text"
                    placeholder="Enter Player's Name"
                    value={nameFilter}
                    onChange={handleChangeOfName}
                    className={styles.filterInput}
                  />
                </div>

              </div>

            </Card>
          </div>
            <DataTable
                columns={columns}
                data={filteredData}
                defaultSortField="xp"
                customTheme={theme()}
                striped = {true}
                pagination = {true}
                defaultSortAsc = {false}
            />
        </div>
    </SubPage>

  );
}
export default HighScore;
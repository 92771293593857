import React from 'react';
import styles from './SubPage.module.scss';
import Bookmarks from '../Bookmarks/Bookmarks'

function SubPage(props) {
  return (
    <div className={styles.subpageWrapper}>

        <header className={styles.pageHeader}>
          <Bookmarks  links={props.bookmarks} />
          <button className={styles.transparentButton}>Back</button>
        </header>
        <div className={styles.subpageContent}>

            {props.children}

        </div>
    </div>
  );
}
export default SubPage;
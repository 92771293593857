import React from 'react';
import Card from '../../components/Card/Card';
import styles from './DownloadCard.module.scss';

function DownloadCard(props) {
  return (
    <li className={styles.downloadCard}>
      <Card>
        <div className={styles.cardContentWrapper}>
        <img src={props.logo} alt=""/>
        <div className="downloadCardContent">
        <h4>
            <a href={props.link}>Download For {props.platform}</a>   
        </h4>
        <p>
            {props.description}
        </p>
        </div>
        </div>
        <div className={styles.cardFooter}>
        <p>
         {props.downloadSize} MB download
        </p>
        </div>
      </Card>

    </li>
         
  );
}
export default DownloadCard;